import { useLocation, Link } from "react-router-dom";
import { Menu } from "lucide-react";

import SelectBase from "../SelectBase";
import { Button } from "../ui/button";
import { Sheet, SheetContent, SheetTrigger } from "../ui/Sheet";

import { cn } from "../../lib/utils";
import { BaseDTO } from "../../models/BaseDTO";
import { ROUTES_PATH } from "../../routes/routesPath";

interface NavBarProps {
  bases?: BaseDTO[];
};

const menuItems = [
  { to: ROUTES_PATH.dashboard, title: 'Dashboard' },
  { to: ROUTES_PATH.fleet, title: 'Veículos' },
  // { to: ROUTES_PATH.reports, title: 'Relatórios' },
];

export default function NavBar({ bases = [] }: NavBarProps) {
  const location = useLocation();

  return (
    <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 z-10">
      <nav
        className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6"
      >
        <Link
          to="/"
          className="text-lg font-semibold md:text-base"
        >
          <span className="text-primary">D</span>FU2RE
        </Link>
        <span>&nbsp;</span>
        {menuItems.map((item, index) => (
          <Link
            key={`nav-${index}`}
            className={cn(
              'transition-colors hover:text-foreground',
              location.pathname === item.to ||
              (item.to !== '/' && location.pathname.startsWith(item.to))
                ? 'text-foreground'
                : 'text-muted-foreground',
            )}
            to={item.to}
          >
            {item.title}
          </Link>
        ))}
      </nav>
      <Sheet>
        <SheetTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            className="shrink-0 md:hidden"
          >
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <nav className="grid gap-6 text-lg font-medium">
            <Link
              className="flex items-center gap-2 text-lg font-semibold"
              to="/dashboard"
            >
              <span className="text-primary">D</span>FUTURE
            </Link>
            {menuItems.map((item, index) => (
              <Link
                key={`mobile-nav-${index}`}
                className={cn(
                  location.pathname === item.to ? 'text-foreground' : 'text-muted-foreground',
                  'transition-colors hover:text-foreground',
                )}
                to={item.to}
              >
                {item.title}
              </Link>
            ))}
          </nav>
        </SheetContent>
      </Sheet>
      <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
        <form className="ml-auto flex-1 sm:flex-initial">
          {!!bases?.length && (
            <SelectBase bases={bases} />
          )}
        </form>
      </div>
    </header>
  );
};
