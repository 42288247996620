import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronsDownIcon, ChevronsUpDownIcon, ChevronsUpIcon } from "lucide-react";

import { Progress } from "@/components/ui/Progress";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { useToast } from "@/hooks/use-toast";
import { DateConverter } from "@/utils/DateConverter";
import { formatBRLNumber } from "@/utils/formatBRLNumber";
import { FilterVehicleType } from "@/models/FilterVehicleType";

interface VehiclesTableProps {
  vehicles: FilterVehicleType[];
};

type FleetTableFilterDirectionType = 'asc' | 'desc' | null;

type FleetTableFilterType = 'localidade'
  | 'nome'
  | 'dataInicialDoVideo'
  | 'dataFinalDoVideo'
  | 'totalHorasDosVideos'
  | 'totalDeHorasEmServico'
  | 'quantidadeTotalDeAlertas'
  | null;

export default function FleetTable({ vehicles }: VehiclesTableProps) {
  const navigate = useNavigate();

  const { toast } = useToast();

  const [direction, setDirection] = useState<FleetTableFilterDirectionType>(null);
  const [filter, setFilter] = useState<FleetTableFilterType>(null);

  const onChangeFilter = (value: FleetTableFilterType) => {
    setFilter((previous) => {
      if (value !== previous) {
        setDirection('asc')
      } else {
        setDirection((previousDirection) => {
          if (previousDirection === 'asc') {
            return 'desc';
          }

          return 'asc';
        })
      }

      return value;
    })
  };

  const sortedVehicles = useMemo(() => {
    if (typeof filter !== 'string' || !filter) {
      return vehicles;
    }

    return vehicles.slice().sort((a, b) => {
      const aValue = a[filter];
      const bValue = b[filter];

      if (aValue === undefined || bValue === undefined) {
        return 0;
      }

      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }

      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }

      return 0;
    });
  }, [direction, filter, vehicles]);

  const renderTableHeadButton = (value: FleetTableFilterType, label: string) => (
    <button
      className="block w-full text-left whitespace-nowrap"
      onClick={() => onChangeFilter(value)}
    >
      {label}
      {' '}
      {filter === value && direction === 'asc' ? (
        <ChevronsDownIcon className="inline-block w-3.5" />
      ) : filter === value && direction === 'desc' ? (
        <ChevronsUpIcon className="inline-block w-3.5" />
      ) : (
        <ChevronsUpDownIcon className="inline-block w-3.5" />
      )}
    </button>
  );

  return (
    <Table>
      <TableHeader className="sticky top-0 bg-white hover:bg-white z-20">
        <TableRow>
          <TableHead>
            {renderTableHeadButton('localidade', 'Base')}
          </TableHead>
          <TableHead>
            {renderTableHeadButton('nome', 'Veículo')}
          </TableHead>
          <TableHead className="hidden lg:table-cell">
            {renderTableHeadButton('dataInicialDoVideo', 'Início')}
          </TableHead>
          <TableHead className="hidden lg:table-cell">
            {renderTableHeadButton('dataFinalDoVideo', 'Fim')}
          </TableHead>
          <TableHead className="hidden sm:table-cell text-center">
            {renderTableHeadButton('totalDeHorasEmServico', 'Serviços')}
          </TableHead>
          <TableHead className="hidden sm:table-cell text-center">
            {renderTableHeadButton('quantidadeTotalDeAlertas', 'Alertas')}
          </TableHead>
          <TableHead className="hidden lg:table-cell">
            {renderTableHeadButton('totalHorasDosVideos', 'Horas gravadas')}
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {sortedVehicles.map((vehicle) => (
          <TableRow
            key={`${vehicle.veiculoId}-${vehicle.localidade}`}
            className="cursor-pointer"
            onClick={() => {
              if (!!vehicle.quantidadeDeVideos) {
                navigate(`/veiculos/${vehicle.veiculoId}`)
              } else {
                toast({
                  description: "Veículo sem videos para o período selecionado",
                })
              }
            }}
          >
            <TableCell className="font-medium">{vehicle.localidade}</TableCell>
            <TableCell className="font-medium">{vehicle.nome}</TableCell>
            <TableCell className="hidden lg:table-cell">
              {DateConverter.ConvertSystemDateFormatToBRLDate(vehicle.dataInicialDoVideo)}
            </TableCell>
            <TableCell className="hidden lg:table-cell">
              {DateConverter.ConvertSystemDateFormatToBRLDate(vehicle.dataFinalDoVideo)}
            </TableCell>
            <TableCell className="hidden sm:table-cell text-center">
              {!!vehicle?.totalDeHorasEmServico
                ? `${formatBRLNumber(vehicle?.totalDeHorasEmServico)} h`
                : ''}
            </TableCell>
            <TableCell className="hidden sm:table-cell text-center">
              {formatBRLNumber(vehicle.quantidadeTotalDeAlertas || 0)}
            </TableCell>
            <TableCell className="hidden w-full min-w-[300px] flex-row items-center gap-2 lg:flex">
              <div className="whitespace-nowrap font-bold w-[48px]">
                {formatBRLNumber(DateConverter.ConvertTimeToHourNumber(vehicle.totalHorasDosVideos))} h
              </div>
              <Progress
                value={vehicle.percentualEmNumero * 100}
                max={100}
              />
              <div className="whitespace-nowrap font-bold w-[56px] text-right">
                {formatBRLNumber(vehicle.percentualTotal, 2)}%
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
