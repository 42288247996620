import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import VideosPlayer from "../../components/VideosPlayer";

import { useLoaderContext } from "../../contexts/LoaderContext";
import { getGenerateLinks, getListVideos } from "../../services/hooks/useVideos";
import { TVehiclesCamerasByDate } from "../../models/TVehiclesCamerasByDate";
import { VideoInputOutputDTO } from "../../models/VideoInputOutputDTO";

export default function Video() {
  const navigate = useNavigate();

  const { startDate = '', veiculoId = '', startHour = '', videoTime = '0' } = useParams();

  const { handleStartLoader } = useLoaderContext();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [allVehicleVideoDates, setAllVehicleVideoDates] = useState<string[]>([]);
  const [allVehicleVideos, setAllVehicleVideos] = useState<TVehiclesCamerasByDate[]>([]);
  const [selectedVehicleVideo, setSelectedVehicleVideo] = useState<VideoInputOutputDTO>();
  const [playbackRate, setPlaybackRate] = useState<number>(1);

  useEffect(() => {
    if (!loaded) {
      handleStartLoader()
    }
  }, [handleStartLoader, loaded]);

  const getRecentVehicleVideos = useCallback(
    async () => {
      if (!veiculoId.length) return;

      const today = new Date();
      const initial = new Date(today);

      initial.setDate(today.getDate() - 59);

      const formattedToday = today.toISOString().split('T')[0];
      const formattedInitial = initial.toISOString().split('T')[0];

      try {
        const vehicleCamera = await getListVideos(
          veiculoId,
          formattedInitial,
          formattedToday,
        );

        setLoaded(true);
        return vehicleCamera;
      } catch (error) {
        console.error(`Error fetching data for ${formattedInitial} - ${formattedToday}:`, error);
      }
    },
    [veiculoId]
  );

  const getSelectedVideoData = useCallback(
    async (selected: VideoInputOutputDTO | null) => {
      if (!selected) return null;

      try {
        const videoLinks = await getGenerateLinks(selected);

        return videoLinks;
      } catch (error) {
        console.error(`Error fetching data for: `, error);
      }

      return null;
    },
    [],
  );

  useEffect(() => {
    if (loaded || !startDate || !veiculoId) return;
  
    const getVideos = async () => {
      const vehicleVideosData = await getRecentVehicleVideos();

      if (!!vehicleVideosData) {
        const videoDays = vehicleVideosData.reduce(
          (accumulator: string[] = [], _video: VideoInputOutputDTO) => (
            !accumulator.includes(_video.dataDoVideo)
              ? [...accumulator, _video.dataDoVideo]
              : accumulator
          ),
          [],
        );

        if (!!videoDays?.length) {
          setAllVehicleVideoDates(videoDays);

          const allVideos = videoDays.map((_date) => ({
            date: _date,
            vehicles: vehicleVideosData.filter(
              (_video) => _video.dataDoVideo === _date,
            ),
          }));

          setAllVehicleVideos(allVideos);
        }
      }
    };
  
    getVideos();
  }, [getRecentVehicleVideos, loaded, startDate, veiculoId]);

  const startDateVehicleVideos = useMemo(() => (
    allVehicleVideos.find((_video) => _video.date === startDate)
  ), [allVehicleVideos, startDate]);

  useEffect(() => {
    const selectedVehicleVideoData = !startHour?.length
      ? startDateVehicleVideos?.vehicles[0]
      : startDateVehicleVideos?.vehicles?.find(
        (_video) => (_video.horaDoVideo === startHour.replace('-', ':')),
      );

    if (!selectedVehicleVideoData) return;

    const fetchData = async () => {
      const videoData = await getSelectedVideoData(selectedVehicleVideoData);

      if (!!videoData) {
        setSelectedVehicleVideo(videoData);
      }

    };

    fetchData();
  }, [getSelectedVideoData, startDateVehicleVideos?.vehicles, startHour]);

  const handleClickPlaybackRate = () => {
    const allowed = [1, 2, 5, 10, 15];

    setPlaybackRate((previous) => (
      allowed[(allowed.indexOf(previous) + 1) % allowed.length]
    ));
  };

  const handleChangeDate = (date: Date) => {
    const formattedDate = date.toISOString().split('T')[0];

    if (!!veiculoId?.length && !!formattedDate.length) {
      navigate(`/veiculos/${veiculoId}/${formattedDate}/videos`);
    }
  };

  const onVideoNavigation = (index: number) => {
    navigate(`/veiculos/${
      veiculoId
    }/${
      startDate
    }/videos/${
      startDateVehicleVideos?.vehicles[index].horaDoVideo.replace(':', '-')
    }`);
  };

  const handleClickNextVideo = () => {
    if (!selectedVehicleVideo || !startDateVehicleVideos?.vehicles) return;

    const index = startDateVehicleVideos?.vehicles?.findIndex(
      (_vehicle) => _vehicle.id === selectedVehicleVideo?.id
    );

    if (index + 1 < startDateVehicleVideos?.vehicles?.length) {
      onVideoNavigation(index + 1)
    }
  };

  const handleClickPreviousVideo = () => {
    if (!selectedVehicleVideo || !startDateVehicleVideos?.vehicles) return;

    const index = startDateVehicleVideos?.vehicles?.findIndex(
      (_vehicle) => _vehicle.id === selectedVehicleVideo?.id
    );

    if (index - 1 >= 0) {
      onVideoNavigation(index - 1)
    }
  };

  return (
    <>
      {!!selectedVehicleVideo && !!startDateVehicleVideos?.vehicles && (
        <>
          <VideosPlayer
            key={`${startDate}-${veiculoId}-${startHour}`}
            veiculoId={veiculoId}
            selectedVideo={selectedVehicleVideo}
            videoStart={(parseInt(videoTime) / 60)}
            videosList={startDateVehicleVideos?.vehicles}
            playbackRate={playbackRate}
            includeDates={allVehicleVideoDates}
            handleClickPlaybackRate={handleClickPlaybackRate}
            handleChangeDate={handleChangeDate}
            handleClickNextVideo={handleClickNextVideo}
            handleClickPreviousVideo={handleClickPreviousVideo}
          />
        </>
      )}
    </>
  );
};
