import FleetDetails from "../FleetDetails";
import FleetTable from "../FleetTable";
import { Card, CardContent } from "../ui/card";
import { ScrollArea } from "../ui/scroll-area";
import { TabsContent } from "../ui/tabs";

import { FilterPeriodType } from "../../models/FilterPeriodType";
import { FilterVehicleType } from "../../models/FilterVehicleType";

interface VehiclesTableProps {
  period: FilterPeriodType;
  vehicles: FilterVehicleType[];
};

export default function FleetTabContent({ period, vehicles }: VehiclesTableProps) {
  return (
    <TabsContent value={period}>
      <Card x-chunk="dashboard-06-chunk-0">
        <FleetDetails vehicles={vehicles} />
        <CardContent>
          <ScrollArea
            className="w-full h-auto lg:h-[calc(100vh-464px)] xl:h-[calc(100vh-436px)]"
            type="hover"
          >
            <FleetTable vehicles={vehicles} />
          </ScrollArea>
        </CardContent>
      </Card>
    </TabsContent>
  );
};
