import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
};

export function padNumber(string: string) {
  return ('0' + string).slice(-2);
};

export function slugfy(string: string) {
  string = string.toLowerCase();
  string = string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  string = string.replace(/[^a-z0-9\s-]/g, '')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
  
  return string;
};

export function convertTotalHoursToNumber(hours: string) {
  const regex = /^(\d+)\.(\d+):(\d+):(\d+)$/;
  const match = hours.match(regex);

  if (!match) {
    return 0;
  }

  const h = parseInt(match[1], 10);
  const m = parseInt(match[2], 10);
  const s = parseInt(match[3], 10);

  return h + (m / 60) + (s / 3600);
};

export function formatDateDDMMYY(value: string) {
  const [year, month, day] = value.split('-');
  const shortYear = year.slice(-2);
  
  return `${day}/${month}/${shortYear}`;
};

export function formatVideoDuration(seconds: number): string {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = padNumber(`${date.getUTCSeconds()}`);

  return `${padNumber(`${(hh / 60) + mm}`)}:${ss}`;
}
