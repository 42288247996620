import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClockIcon, TriangleAlertIcon } from "lucide-react";

import Tooltip from "@/components/Tooltip";
import { TVehiclesCamerasByDate } from "@/models/TVehiclesCamerasByDate";
import { VideoInputOutputDTO } from "@/models/VideoInputOutputDTO";
import { cn, formatDateDDMMYY } from "@/lib/utils";

import { Card, CardContent } from '@/components/ui/card';
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { DateConverter } from "@/utils/DateConverter";

interface VideosTableProps {
  vehicleVideos: TVehiclesCamerasByDate[];
};

export default function VideosTable({ vehicleVideos }: VideosTableProps) {
  const navigate = useNavigate();

  const [minHour, setMinHour] = useState<number>(8);
  const [maxHour, setMaxHour] = useState<number>(18);

  useEffect(() => {
    vehicleVideos?.forEach((day) => {
      day.vehicles?.forEach((vehicle) => {
        const hour = vehicle.horaDoVideo.split(':')[0];

        setMinHour((previous) => (
          parseInt(hour) < previous ? parseInt(hour) : previous
        ));

        setMaxHour((previous) => (
          parseInt(hour) > previous ? parseInt(hour) : previous
        ));
      })
    })
  }, [maxHour, minHour, vehicleVideos]);

  const hoursHeader = useMemo(() => (
    Array.from({ length: maxHour - minHour + 1 }, (_, i) => minHour + i)
  ), [maxHour, minHour]);

  const dayRows = useMemo(() => {
    const rows = [] as { date: string; values: (VideoInputOutputDTO | null)[] }[];

    vehicleVideos.forEach(({ date, vehicles }) => {
      const data = [] as (VideoInputOutputDTO | null)[];

      hoursHeader.forEach((hour) => {
        [15, 30, 45, 60].forEach((minute) => {
          const currentTime = hour * 60 + minute;
          const video = vehicles.find((item) => {
            if (item.dataDoVideo !== date) return false;
  
            const [videoHour, videoMinute] = item.horaDoVideo.split(':').map(Number);
            const videoStartTime = videoHour * 60 + videoMinute;
            const [duracaoHours, duracaoMinutes] = item.duracaoDoVideo.split(':').map(Number);
            const durationInMinutes = duracaoHours * 60 + duracaoMinutes;
            const videoEndTime = videoStartTime + durationInMinutes;

            return currentTime >= videoStartTime && currentTime <= videoEndTime;
          }) || null;

          data.push(video);
        });
      });

      rows.push({ date, values: data });
    });

    return rows;
  }, [hoursHeader, vehicleVideos]);

  return (
    <Card x-chunk="dashboard-06-chunk-0">
      {!!dayRows?.length ? (
        <ScrollArea
          className="w-full lg:max-h-[calc(100vh-520px)]"
          type="hover"
          style={{ height: 'inherit' }}
        >
          <CardContent className="lg:max-h-[calc(100vh-520px)]">
            <table className="w-fit border-collapse border-border text-sm">
              <thead className="sticky top-0 bg-white hover:bg-white z-20">
                <tr>
                  <th className="w-fit px-2 py-1 border-b border-r border-border">
                    &nbsp;
                  </th>
                  {hoursHeader.map((hour, index) => (
                    <th
                      key={hour}
                      className={cn(
                        'w-64 px-2.5 py-1.5 relative border-b border-border text-xs',
                        'before:contet-[\'\'] before:absolute before:top-0 before:-right-[1px] before:h-full before:w-0.5 before:bg-background',
                        index < hoursHeader.length - 1 && 'after:contet-[\'\'] after:absolute after:top-0 after:-right-[1px] after:h-full after:w-[1px] after:bg-border',
                      )}
                      colSpan={4}
                    >
                      {`${hour < 10 ? '0' : ''}${hour}:00`}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dayRows.map(({ date, values }) => (
                  <tr key={date}>
                    <td className="w-fit px-2.5 py-1.5 border-b border-r">
                      {formatDateDDMMYY(date)}
                    </td>
                    {values.map((value, index) => {
                      const alertasAi = [
                        ...(value?.camera1?.alertasAi || []),
                        ...(value?.camera2?.alertasAi || []),
                        ...(value?.camera3?.alertasAi || []),
                        ...(value?.camera4?.alertasAi || []),
                      ];

                      const servicesAi = [
                        ...(value?.camera1?.servicosAi || []),
                        ...(value?.camera2?.servicosAi || []),
                        ...(value?.camera3?.servicosAi || []),
                        ...(value?.camera4?.servicosAi || []),
                      ];

                      const mobileUsageAlertCount = alertasAi.filter(
                        ({ aiModel }) => aiModel === 'USANDO_CELULAR',
                      );

                      return (
                        <td
                          key={`${value}-${index}`}
                          className="w-64 py-2.5 px-0 border-b border-border border-l"
                        >
                          {!!value && (
                            <div className="relative">
                              <Tooltip
                                content={`${
                                  DateConverter.ConvertStringTimeToBRLTime(value.inicioDoVideo)
                                } - ${
                                  DateConverter.ConvertStringTimeToBRLTime(value.fimDoVideo)
                                }`}
                                icon={<ClockIcon size="18" />}
                              >
                                <button
                                  className={cn(
                                    'w-full flex justify-center items-center h-[1.25rem] bg-chart-100',
                                    !values[index - 1] && 'rounded-l-sm',
                                    !values[index + 1] && 'rounded-r-sm',
                                    !!values[index + 1] && 'relative after:content-[\'\'] after:block after:w-0.5 after:h-[1.25rem] after:absolute after:top-0 after:-right-0.5 after:bg-chart-100',
                                  )}
                                  onClick={() => {
                                    navigate(`/veiculos/${
                                      value.veiculoId
                                    }/${
                                      value.dataDoVideo
                                    }/videos/${
                                      value.horaDoVideo.replace(':', '-')
                                    }`)
                                  }}
                                />
                              </Tooltip>
                              {!!value?.videoDeServico && !!alertasAi.length ? (
                                <Tooltip
                                  content={`Serviço e Alerta${
                                    alertasAi.length > 1 ? 's' : ''
                                  }: ${[
                                    `Usando Celular (${mobileUsageAlertCount.length})`
                                  ].join(' ')}`}
                                >
                                  <div className="w-2 h-2 absolute top-1.5 left-1/2 -translate-x-1/2 flex rounded-full overflow-hidden cursor-pointer">
                                    <div className="bg-amber-400 w-full h-2" />
                                    <div className="bg-green-400 w-full h-2" />
                                  </div>
                                </Tooltip>
                              ) : !!alertasAi.length ? (
                                <Tooltip
                                  content={[
                                    `Alerta${alertasAi.length > 1 ? 's' : ''}`,
                                    `(${alertasAi.length}):`,
                                    mobileUsageAlertCount?.length ? 'Celular' : '',
                                  ].join(' ')}
                                  icon={<TriangleAlertIcon size="18" />}
                                  color="bg-amber-400"
                                >
                                  <button
                                    className="w-2 h-2 absolute top-1.5 left-1/2 -translate-x-1/2 rounded-full cursor-pointer bg-amber-400"
                                    onClick={() => {
                                      navigate(`/veiculos/${
                                        value.veiculoId
                                      }/${
                                        value.dataDoVideo
                                      }/videos/${
                                        value.horaDoVideo.replace(':', '-')
                                      }/${alertasAi[0].startMoment}`)
                                    }}
                                  />
                                </Tooltip>
                              ) : !!value?.videoDeServico && !!servicesAi.length ? (
                                <Tooltip content="Serviço">
                                  <button
                                    className="w-2 h-2 absolute top-1.5 left-1/2 -translate-x-1/2 rounded-full cursor-pointer bg-green-400"
                                    onClick={() => {
                                      navigate(`/veiculos/${
                                        value.veiculoId
                                      }/${
                                        value.dataDoVideo
                                      }/videos/${
                                        value.horaDoVideo.replace(':', '-')
                                      }/${Math.floor(servicesAi[0].startMoment)}`)
                                    }}
                                  />
                                </Tooltip>
                              ) : null}
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
          <ScrollBar orientation="vertical" />
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      ) : (
        <CardContent className="lg:max-h-[calc(100vh-520px)]">
          <p className="text-center text-sm pt-12 pb-6">
            Não existem vídeos desse veículo para o período selecionado
          </p>
        </CardContent>
      )}
    </Card>
  );
};
