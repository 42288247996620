import { useQuery } from 'react-query';

import { http } from '../../useAxios';
import { FilterVehicleType } from '../../../models/FilterVehicleType';

export async function getByLastWeek(filter:string): Promise<FilterVehicleType[]> {
  try {
    const { data: vehiclesByWeek } = await http.get<FilterVehicleType[]>(
      `/Videos/ListarVeiculosUltimaSemana?filter=${filter}`,
    );

    return vehiclesByWeek;
  } catch (error) {
    console.log(error);

    return [];
  }
};

export function useFilterVehiclesByLastWeek(filter?: string) {
  const ft = filter || 'all';

  return useQuery({
    queryKey: [`vehicles-by-last-week-${ft}`],
    queryFn: () => getByLastWeek(ft),
    staleTime: 1000 * 60 * 60,
    enabled: true,
  });
};
