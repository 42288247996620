import { useEffect, ReactElement } from 'react';
import {
  useMsal,
  useIsAuthenticated,
  AuthenticatedTemplate,
} from '@azure/msal-react';

interface PrivateRouteProps {
  element: ReactElement; // Componente a ser renderizado
};

export default function PrivateRoute({ element }: PrivateRouteProps) {
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    if (inProgress === 'none' && !isAuthenticated) {
      instance.loginRedirect()
    }
  }, [instance, isAuthenticated, inProgress])

  return (
    <AuthenticatedTemplate>
      {element}
    </AuthenticatedTemplate>
  )
};
