import { Route, Routes } from "react-router-dom";

import Dashboard from "../views/Dashboard";
import Fleet from "../views/Fleet";
import Vehicle from "../views/Vehicle";
import Video from "../views/Video";

import PrivateRoute from "./PrivateRoute";
import { useAPIToken } from "../services/hooks";
import { ROUTES_PATH } from "./routesPath";

export function Router() {
  useAPIToken();

  return (
    <Routes>
      <Route
        path={ROUTES_PATH.dashboard}
        element={<PrivateRoute element={<Dashboard />} />}
      />
      <Route
        path={ROUTES_PATH.fleet}
        element={<PrivateRoute element={<Fleet />} />}
      />
      <Route
        path={ROUTES_PATH.veiculo}
        element={<PrivateRoute element={<Vehicle />} />}
      />
      <Route
        path={ROUTES_PATH.videos}
        element={<PrivateRoute element={<Video />} />}
      />
    </Routes>
  );
};
