import { ReactNode, createContext, useContext, useState } from "react";
import { Loader2Icon } from "lucide-react";

interface LoaderContextType {
  loader: boolean;
  handleStartLoader: () => void;
  handleStopLoader: () => void;
}

const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

interface LoaderProviderProps {
  children: ReactNode;
}

export const LoaderProvider = ({ children }: LoaderProviderProps) => {
  const [loader, setLoader] = useState<boolean>(true);

  const handleStartLoader = () => setLoader(true);
  
  const handleStopLoader = () => setLoader(false);

  return (
    <LoaderContext.Provider value={{ loader, handleStartLoader, handleStopLoader }}>
      {children}
      {!!loader && (
        <div className="w-full h-screen fixed top-0 left-0 z-50 flex justify-center items-center bg-foreground/90">
          <Loader2Icon className="size-12 animate-spin text-accent" />
        </div>
      )}
    </LoaderContext.Provider>
  );
};

export const useLoaderContext = (): LoaderContextType => {
  const context = useContext(LoaderContext);

  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }

  return context;
};
