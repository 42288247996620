import { useQuery } from "react-query";

import { http } from "../../useAxios";

export async function getVideosDownload(bucketId: string): Promise<string> {
  const { data } = await http.get(`Videos/Download/${bucketId}`);
  
  return data;
};

export function useVideosDownload(bucketId: string) {
  return useQuery(
    `videos-download-${bucketId}`,
    () => getVideosDownload(bucketId),
    {
      staleTime: 1000 * 60 * 60,
      enabled: true,
    },
  );
};
