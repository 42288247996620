import { useQuery } from 'react-query'
import { DatesRangeValue } from '@mantine/dates';

import { http } from '../../useAxios';
import { FilterVehicleType } from '../../../models/FilterVehicleType';
import { DateConverter } from '../../../utils/DateConverter';
import { useFilterContext } from '../../../contexts/FilterContext';

export async function getByRange(
  dateRange: DatesRangeValue,
  filter: string,
): Promise<FilterVehicleType[]> {
  try {
    const [from, to] = dateRange;

    if (!from || !to) {
      return [];
    }

    const formattedFrom = DateConverter.ConvertDateToSystemDateFormat(from);
    const formattedTo = DateConverter.ConvertDateToSystemDateFormat(to);
    const { data: vehiclesByToday } = await http.get<FilterVehicleType[]>(
      `/Videos/ListarVeiculosPorData/${formattedFrom}/${formattedTo}?filter=${filter}`,
    );

    return vehiclesByToday;
  } catch (error) {
    console.log(error);

    return [];
  }
};

export function useFilterVehiclesByRange(dateRange: DatesRangeValue, filter?: string) {
  const { filterDateRange } = useFilterContext();

  let start = '';
  let final = '';

  if (!!dateRange?.[0]) {
    start = dateRange?.[0].toISOString().split('T')[0];
    final = start
  }

  if (!!dateRange?.[1]) {
    final = dateRange?.[1].toISOString().split('T')[0];
  }

  return useQuery(
    `vehicles-by-range-${filter || 'all'}-${start}-${final}`,
    () => { return getByRange(dateRange, filter ?? 'all') },
    {
      staleTime: 1000 * 60 * 60,
      enabled: !!filterDateRange && !!dateRange,
    },
  );
};
