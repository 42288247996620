import { useMemo } from "react";
import { CalendarIcon } from "lucide-react";
import { pt } from "date-fns/locale";

import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from "../ui/popover";

import { useFilterContext } from "../../contexts/FilterContext";
import { cn, formatDateDDMMYY } from "../../lib/utils";

export default function FilterPeriodDatePicker() {
  const {
    displayDateRange,
    filterDateRange,
    filterPeriod,
    setDisplayDateRange,
    setFilterDateRange,
    setFilterPeriod,
  } = useFilterContext();

  const disabledMax = useMemo(() => new Date(), []);

  const disabledMin = useMemo(() => {
    const current = new Date();
  
    current.setDate(disabledMax.getDate() - 59);

    return current;
  }, [disabledMax]);

  return (
    <div className="flex items-center gap-2">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            className="w-fit h-fit text-muted-foreground hover:text-foreground p-1"
            variant="secondary"
          >
            <span className={cn(
              'flex items-center px-2.5 py-1.5 rounded-sm',
              filterPeriod === 'range' && !!filterDateRange && 'bg-white',
            )}>
              <CalendarIcon className="mr-2 h-4 w-4" />
              Filtrar por período
            </span>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-0 bg-white border mt-2 z-30"
          align="start"
        >
          <Calendar
            locale={pt}
            initialFocus
            mode="range"
            defaultMonth={
              displayDateRange?.from ||
              new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
            }
            selected={displayDateRange}
            onSelect={setDisplayDateRange}
            numberOfMonths={2}
            disabled={{
              before: disabledMin,
              after: disabledMax
              ,
            }}
          />
          <PopoverClose className="px-4 pb-4 flex flex-col justify-end w-full">
            <Button
              className="w-fit ml-auto"
              size="sm"
              onClick={() => {
                setFilterPeriod(!!displayDateRange ? 'range' : 'lastWeek');
                setFilterDateRange(displayDateRange);
              }}
            >
              Confirmar
            </Button>
          </PopoverClose>
        </PopoverContent>
      </Popover>
      {!!displayDateRange?.from && (
        <p className="text-sm">
          {`${formatDateDDMMYY(displayDateRange.from.toISOString().split('T')[0])}${
            !!displayDateRange?.to
              ? ` até ${formatDateDDMMYY(displayDateRange.to.toISOString().split('T')[0])}`
              : ''
          }`}
        </p>
      )}
    </div>
  );
};
