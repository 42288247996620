import { Dispatch, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { DateRange } from "react-day-picker";

interface FilterContextType {
  base: string;
  filterDateRange?: DateRange;
  displayDateRange?: DateRange;
  filterPeriod: string;
  setBase: (value: string) => void;
  setFilterDateRange: Dispatch<DateRange | undefined>;
  setDisplayDateRange: Dispatch<DateRange | undefined>;
  setFilterPeriod: (value: string) => void;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

interface FilterProviderProps {
  children: ReactNode;
}

export const FilterProvider = ({ children }: FilterProviderProps) => {
  const [base, setBase] = useState<string>('all');
  const [filterDateRange, setFilterDateRange] = useState<DateRange | undefined>();
  const [filterPeriod, setFilterPeriod] = useState<string>('lastWeek');

  const [displayDateRange, setDisplayDateRange] = useState<DateRange | undefined>(() => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);

    sevenDaysAgo.setDate(today.getDate() - 6);

    return ({
      from: sevenDaysAgo,
      to: today,
    })
  });

  useEffect(() => {
    if (!!filterDateRange) {
      setDisplayDateRange(filterDateRange)
    }
  }, [filterDateRange]);

  return (
    <FilterContext.Provider
      value={{
        base,
        filterDateRange,
        displayDateRange,
        filterPeriod,
        setBase,
        setFilterDateRange,
        setDisplayDateRange,
        setFilterPeriod,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = (): FilterContextType => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  return context;
};
