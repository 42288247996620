import { useMemo } from "react";
import {
  Label,
  PolarGrid,
  PolarRadiusAxis,
  RadialBar,
  RadialBarChart,
} from "recharts";

import SingleValueCard from "@/components/SingleValueCard";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { ChartConfig, ChartContainer } from '@/components/ui/chart';

import { DateConverter } from "@/utils/DateConverter";
import { formatBRLNumber } from '@/utils/formatBRLNumber';
import { FilterVehicleType } from "@/models/FilterVehicleType";

const chartConfig = {
  values: {
    label: "Veículos",
  },
  active: {
    label: "Active",
    color: "var(--chart-1)",
  },
} satisfies ChartConfig

interface RecordingHoursChartProps {
  description?: string;
  vehicles: FilterVehicleType[];
};

export default function RecordingHoursChart({
  description,
  vehicles,
}: RecordingHoursChartProps) {
  const value = useMemo(() => (
    vehicles.reduce((accumulator, vehicle) => (
      accumulator + DateConverter.ConvertTimeToHourNumber(vehicle.totalHorasDosVideos)
    ), 0)
  ), [vehicles]);

  const total = 0;
  // const total = useMemo(() => (
  //   vehicles.reduce((accumulator, vehicle) => (
  //     accumulator + (
  //       DateConverter.ConvertTimeToHourNumber(vehicle.totalHorasDosVideos)
  //       / (vehicle.percentualEmNumero || 1)
  //     )
  //   ), 0)
  // ), [vehicles]);

  const chartData = useMemo(() => ([{
    browser: "active",
    values: Math.floor(value || 0),
    fill: "var(--color-active)",
  }]), [value]);

  const percentage = useMemo(() => value / (total || 1) || 0, [total, value]);

  const percentageChart = useMemo(() => {
    let current = percentage;

    if (current < 0) {
      return 0;
    }

    if (current > 1) {
      return 1;
    }

    return current || 0;
  }, [percentage]);

  return !!total ? (
    <Card className="flex flex-col justify-between">
      <CardHeader className="items-center pb-0">
        <CardTitle>Horas de gravação</CardTitle>
        {!!description && (<CardDescription>{description}</CardDescription>)}
      </CardHeader>
      <CardContent className="flex-1 p-0 my-1 w-full max-h-[192px]">
        <ChartContainer className="mx-auto h-full" config={chartConfig}>
          <RadialBarChart
            data={chartData}
            startAngle={0}
            endAngle={360 * percentageChart}
            innerRadius={64}
            outerRadius={100}
          >
            <PolarGrid
              gridType="circle"
              radialLines={false}
              stroke="none"
              className="first:fill-muted last:fill-background"
              polarRadius={[70, 56]}
            />
            <RadialBar dataKey="values" background cornerRadius={10} />
            <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-3xl font-bold"
                        >
                          {formatBRLNumber(chartData[0].values)}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          horas
                        </tspan>
                      </text>
                    )
                  }
                }}
              />
            </PolarRadiusAxis>
          </RadialBarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">
        <div className="flex items-center gap-2 font-medium leading-none">
          {`${
            Math.floor(value)
          } / ${
            Math.floor(total)
          } (${
            (percentage * 100).toFixed(2)
          }%)`}
        </div>
        <div className="leading-none text-muted-foreground mb-4">
          horas processadas
        </div>
        <a
          className="flex items-center gap-2 font-medium leading-none hover:underline"
          href="/veiculos"
        >
          ver detalhes
        </a>
      </CardFooter>
    </Card>
  ) : (
    <SingleValueCard
      title="Horas de gravação"
      description={description}
      value={chartData[0].values}
      unit="horas"
      label="horas processadas"
      link={{ href: '/veiculos', text: 'ver detalhes' }}
    />
  );
};
