import dayjs from "dayjs";
import { useQuery } from "react-query";

import { http } from "../../useAxios";

import { VideoInputOutputDTO } from "../../../models/VideoInputOutputDTO";

export async function getListVideos(
  veiculoId: string,
  dataInicialDoVideo: string,
  dataFinalDoVideo?: string,
): Promise<VideoInputOutputDTO[]> {
  if (veiculoId === null || dataInicialDoVideo === null) {
    return [];
  }

  const initialDateInDayJS = dayjs(dataInicialDoVideo);
  const finalDateInDayJS = dataFinalDoVideo
    ? dayjs(dataFinalDoVideo)
    : dayjs(dataInicialDoVideo);

  const { data } = await http.get<VideoInputOutputDTO[]>(
    `Videos/ListarVideos/${
      veiculoId
    }/${
      initialDateInDayJS.format('YYYY-MM-DD')
    }/${
      finalDateInDayJS.format('YYYY-MM-DD')
    }`,
  );

  return data;
};

export function useListVideos(
  veiculoId: string,
  dataInicialDoVideo: string,
  dataFinalDoVideo?: string,
) {
  let start = dataInicialDoVideo;
  let final = dataFinalDoVideo || dataInicialDoVideo;

  return useQuery({
    queryKey: [`vehicles-videos-by-range-${veiculoId}-${start}-${final}`],
    queryFn: () => getListVideos(
      veiculoId,
       dataInicialDoVideo,
       dataFinalDoVideo,
      ),
    staleTime: 1000 * 60 * 60,
    enabled: true,
  });
};