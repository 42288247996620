import { useQuery } from "react-query";

import { http } from "../../useAxios";
import { VideoInputOutputDTO } from "../../../models/VideoInputOutputDTO";

export async function getGenerateLinks(
  selectedVideo: VideoInputOutputDTO | null,
): Promise<VideoInputOutputDTO> {
  if (!selectedVideo) {
    return {} as VideoInputOutputDTO;
  }

  try {
    const { data: links } = await http.post<VideoInputOutputDTO>(
      '/Videos/GeraLinks',
      selectedVideo,
    );

    return links;
  } catch (error) {
    console.error(error);

    return {} as VideoInputOutputDTO;
  }
};

export function useGenerateLinks(selectedVideo: VideoInputOutputDTO | null) {
  return useQuery(
    `videos-links-${selectedVideo?.id}`,
    () => getGenerateLinks(selectedVideo),
    {
      staleTime: 1000 * 60 * 60,
      enabled: true,
    },
  );
};
