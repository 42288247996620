import { useQuery } from 'react-query';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { authStore } from '../../store/useAuth/useAuth';

async function getToken(
  instance: IPublicClientApplication,
  account: AccountInfo,
): Promise<string> {
  const token = (
    await instance.acquireTokenSilent({
      scopes: [process.env.REACT_APP_API_SCOPE_VMS!],
      account,
    })
  ).accessToken;

  authStore.setState({ token });

  return token;
};

export function useAPIToken() {
  const { instance, accounts } = useMsal();

  return useQuery(
    'api-token',
    () => getToken(instance, accounts[0]),
    {
      staleTime: 1000 * 60 * 30,
      keepPreviousData: true,
    },
  );
};
