import React from "react";

import {
  TooltipProvider,
  Tooltip as TooltipRoot,
  TooltipTrigger,
  TooltipPortal,
  TooltipContent,
  TooltipArrow,
} from "../ui/tooltip";

import { cn } from "../../lib/utils";

interface TooltipProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  color?: string;
  content: string;
};

export default function Tooltip({ children, content, icon, color }: TooltipProps) {
  return (
    <TooltipProvider>
      <TooltipRoot>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            className="bg-white p-2 shadow-lg rounded"
            sideOffset={5}
          >
            <div className="flex gap-2 items-center text-foreground">
              {icon && (icon)}
              <p className="text-sm">{content}</p>
              {!!color && (
                <div className={cn('w-2 h-2 rounded-full', color)} />
              )}
            </div>
            <TooltipArrow className="TooltipArrow fill-white" />
          </TooltipContent>
        </TooltipPortal>
      </TooltipRoot>
    </TooltipProvider>
  );
};
