import { useQuery } from 'react-query';

import { http } from '../../useAxios';
import { FilterVehicleType } from '../../../models/FilterVehicleType';

export async function getByLastYesterday(filter: string): Promise<FilterVehicleType[]> {
  try {
    const { data: vehiclesByYesterday } = await http.get<FilterVehicleType[]>(
      `/Videos/ListarVeiculosOntem?filter=${filter}`,
    );

    return vehiclesByYesterday;
  } catch (error) {
    console.log(error);

    return [];
  }
};

export function useFilterVehiclesByYesterday(filter?: string) {
  const ft = filter || 'all';

  return useQuery({
    queryKey: [`vehicles-by-last-yesterday-${ft}`],
    queryFn: () => getByLastYesterday(ft),
    staleTime: 1000 * 60 * 60,
    enabled: true,
  });
};
