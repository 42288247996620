import { forwardRef, useMemo, MouseEventHandler } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { ChevronLeftIcon, ChevronRightIcon, CalendarIcon } from 'lucide-react';
import { ptBR } from "date-fns/locale";

import { VideoInputOutputDTO } from '../../../../models/VideoInputOutputDTO';
import { cn } from '../../../../lib/utils';

import 'react-datepicker/dist/react-datepicker.css';

interface VideosModalProps {
  selectedVideo: VideoInputOutputDTO;
  includeDates: string[];
  handleChangeDate: (date: Date) => void;
};

type ButtonInputProps = {
  value?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const monthsPt = [
  'janeiro',
  'fevereiro',
  'março',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro',
];

const ButtonInput = forwardRef<HTMLButtonElement, ButtonInputProps>(({ value, onClick }, ref) => (
  <button
    onClick={onClick}
    ref={ref}
    type="button"
    className='flex w-full border border-white whitespace-nowrap px-3 py-2 rounded-2xl'
  >
    <CalendarIcon className="block mr-2" size={24} />
    <div className="w-full">
      {value && (() => {
        const [month, day, year] = value.split('/').map(Number);
        const date = new Date(year, month - 1, day);

        const formattedDate = `${String(day).padStart(2, '0')} de ${monthsPt[date.getMonth()]} de ${year}`;

        return formattedDate;
      })()}
    </div>
  </button>
));

export default function VideoDatePicker({
  selectedVideo,
  includeDates,
  handleChangeDate,
}: VideosModalProps) {
  registerLocale("ptBr", ptBR);

  const selectedDay: Date = useMemo(() => {
    let current = new Date(selectedVideo.dataDoVideo);

    current.setDate(current.getDate() + 1);

    return current;
  }, [selectedVideo.dataDoVideo]);

  return (
    <div className="relative w-full">
      <DatePicker
        wrapperClassName="w-full relative"
        selected={selectedDay}
        onChange={(date) => {
          if (date) {
            date.setDate(date.getDate() - 1);
            handleChangeDate(date)
          }
        }}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        popperClassName="react-datepicker-left"
        customInput={(<ButtonInput />)}
        includeDates={includeDates.map((d) => {
          const current = new Date(d);

          current.setDate(current.getDate() + 1);

          return current;
        })}
        formatWeekDay={(date) => ({
          'Sunday': 'Seg',
          'Monday': 'Ter',
          'Tuesday': 'Qua',
          'Wednesday': 'Qui',
          'Thursday': 'Sex',
          'Friday': 'Sáb',
          'Saturday': 'Dom',
        })?.[date] as string}
        renderCustomHeader={({
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          monthDate,
        }) => (
          <div className="flex items-center justify-between px-2 py-2">
            <div className="text-base pl-1.5 capitalize font-bold">
              {monthsPt?.[monthDate.getMonth()] || ''}
            </div>
            <div className="space-x-2">
              <button
                className={cn(
                  prevMonthButtonDisabled && 'cursor-not-allowed opacity-50',
                  'inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500',
                )}
                type="button"
                disabled={prevMonthButtonDisabled}
                onClick={decreaseMonth}
              >
                <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
              </button>
              <button
                type="button"
                disabled={nextMonthButtonDisabled}
                className={cn(
                  nextMonthButtonDisabled && 'cursor-not-allowed opacity-50',
                  'inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500'
                )}
                onClick={increaseMonth}
              >
                <ChevronRightIcon className="w-5 h-5 text-gray-600" />
              </button>
            </div>
          </div>
        )}
      />
    </div>
  );
};
