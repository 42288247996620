import { useQuery } from "react-query";

import { http } from "../../useAxios";
import { BaseDTO } from "../../../models/BaseDTO";

export async function getListBases(): Promise<BaseDTO[]> {
  try {
    const { data: bases } = await http.get<BaseDTO[]>('/Videos/ListarBases');

    return bases;
  } catch (error) {
    console.log(error);

    return [];
  }
};

export function useListBases() {
  return useQuery({
    queryKey: ['bases-links'],
    queryFn: () => getListBases(),
    staleTime: 1000 * 60 * 60,
    enabled: true,
  });
};
