export class DateConverter {
  public static ConvertSystemDateFormatToBRLDate(date: string) {
    const [year, month, day] = date.split('-');

    if (!year || !month || !day) {
      return null;
    }

    return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year.substring(2, 4)}`;
  };

  public static ConvertBRLDateToSystemDateFormat(date: string) {
    const [day, month, year] = date.split('/');

    if (!day || !month || !year || year.length !== 4) {
      return null;
    }

    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  };

  public static ConvertStringTimeSpanToHourMinuteSecond(timeSpan: string) {
    const [hour, minute, second] = timeSpan.split(':');

    if (!hour || !minute || !second) {
      return null;
    }

    return `${hour}h ${minute}m`;
  };

  public static ConvertDateToSystemDateFormat(date: Date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  public static ConvertTimeToHourNumber(date?: string, round: boolean = true) {
    if (!date) {
      return 0;
    }

    if (!(/^\d+\./).test(date)) {
      date = `0.${date}`;
    }

    let [days, time] = date.split('.');
    let [hour, minute, second] = time.split(':').map(Number);
    let total = (parseInt(days) * 24) + hour + (minute / 60) + (second / 3600);

    return round ? Math.round(total) : total;
  };

  public static ConvertTimeToHourFormat(date: string) {
    return `${this.ConvertTimeToHourNumber(date)} h`;
  };

  public static ConvertStringTimeToBRLTime(time: string) {
    let [hour, minutes] = time.split(':');
    return `${hour}:${minutes}`;
  };
};
