import { useQuery } from "react-query";

import { http } from "../../useAxios";
import { CorteDoVideo } from "../../../models/CorteDoVideo";

export async function getGenerateClip(corteDoVideoDTO: CorteDoVideo): Promise<any> {
  const { fimDoVideo, inicioDoVideo } = corteDoVideoDTO;

  const [inicioHour, inicioMinute, inicioSecond] = inicioDoVideo.split(':');
  const [fimHour, fimMinute, fimSecond] = fimDoVideo.split(':');

  const { data } = await http.post(
    'Videos/GeraCorte',
    {
      ...corteDoVideoDTO,
      inicioDoVideo: `${
        String(inicioHour).padStart(2, '0')
      }:${
        String(inicioMinute).padStart(2, '0')
      }:${
        String(inicioSecond).padStart(2, '0')
      }`,
      fimDoVideo: `${
        String(fimHour).padStart(2, '0')
      }:${
        String(fimMinute).padStart(2, '0')
      }:${
        String(fimSecond).padStart(2, '0')
      }`,
    },
    {
      responseType: 'blob',
    },
  );

  return data;
};

export function useGenerateClip(corteDoVideoDTO: CorteDoVideo) {
  return useQuery(
    `videos-clip-${
      corteDoVideoDTO?.camera
    }-${
      corteDoVideoDTO?.acoes
    }-${
      corteDoVideoDTO?.inicioDoVideo
    }-${
      corteDoVideoDTO?.fimDoVideo
    }`,
    () => getGenerateClip(corteDoVideoDTO),
    {
      staleTime: 1000 * 60 * 60,
      enabled: true,
    },
  );
};
