export function formatBRLNumber(value: string | number, maxDecimalPlaces: number = 0): string {
  const number = typeof value === 'string' ? parseFloat(value) : value;

  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDecimalPlaces,
  };

  let formattedNumber = number.toLocaleString('pt-BR', options);

  if (formattedNumber.includes(',')) {
    const [wholePart, decimalPart] = formattedNumber.split(',');

    if (parseFloat(decimalPart) === 0 || parseFloat(decimalPart) === 0) {
      formattedNumber = wholePart;
    } else {
      formattedNumber = wholePart + ',' + decimalPart.replace(/0+$/, '');
    }
  }

  return formattedNumber;
};
