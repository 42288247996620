import { useMemo } from "react";
import { Label, Pie, PieChart } from "recharts";

import SingleValueCard from "@/components/SingleValueCard";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

import { formatBRLNumber } from "@/utils/formatBRLNumber";
import { slugfy } from "@/lib/utils";
import { FilterAlertType } from "@/models/FilterAlertType";

interface AlertsChartProps {
  description?: string;
  alerts: FilterAlertType[];
};

export default function AlertsChart({
  description,
  alerts,
}: AlertsChartProps) {
  const data = useMemo(() => {
    const current = [] as { alert: string, count: number}[];

    alerts.forEach(({ label, quantidade }) => {
      current.push({ alert: label, count: quantidade })
    });

    return current.sort((a, b) => b.count - a.count);
  }, [alerts]);

  const chartConfig = useMemo(() => {
    const current = {} as { [key: string]: any };

    data.forEach(({ alert }, index) => {
      const slug = slugfy(alert);

      current[`${slug}`] = {
        label: `${alert} `,
        color: `var(--chart-${index + 1})`,
      }
    });

    return current as ChartConfig;
  }, [data]);

  const chartData = useMemo(() => {
    const current: { alerts: string; values: number; fill: string }[] = [];

    data.forEach(({ alert, count }) => {
      const slug = slugfy(alert);

      current.push({
        alerts: slug,
        values: count,
        fill: `var(--color-${slug})`,
      });
    });

    return current;
  }, [data]);

  const total = useMemo(() => (
    chartData.reduce((accumulator, current) => accumulator + current.values, 0) || 0
  ), [chartData]);

  return chartData?.length ? (
    <Card className="flex flex-col justify-between h-full">
      <div>
        <CardHeader className="items-center pb-0">
          <CardTitle>Alertas</CardTitle>
          {!!description && (
            <CardDescription>{description}</CardDescription>
          )}
        </CardHeader>
        <CardContent className="flex-1 pb-0 my-1 max-h-[192px]">
          <ChartContainer
            config={chartConfig}
            className="mx-auto aspect-square max-h-[192px]"
          >
            <PieChart>
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent hideLabel />}
              />
              <Pie
                data={chartData}
                dataKey="values"
                nameKey="alerts"
                innerRadius={50}
                outerRadius={70}
                strokeWidth={0}
              >
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                      return (
                        <text
                          x={viewBox.cx}
                          y={viewBox.cy}
                          textAnchor="middle"
                          dominantBaseline="middle"
                        >
                          <tspan
                            className="fill-foreground text-3xl font-bold"
                            x={viewBox.cx}
                            y={viewBox.cy}
                          >
                            {formatBRLNumber(total)}
                          </tspan>
                          <tspan
                            className="fill-muted-foreground"
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) + 24}
                          >
                            alertas
                          </tspan>
                        </text>
                      );
                    }
                  }}
                />
              </Pie>
            </PieChart>
          </ChartContainer>
        </CardContent>
      </div>
      <CardFooter className="flex-col gap-2 text-sm">
        <a
          className="flex items-center gap-2 font-medium leading-none hover:underline"
          href="/veiculos"
        >
          ver detalhes
        </a>
      </CardFooter>
    </Card>
  ) : (
    <SingleValueCard
      title="Alertas"
      description={description}
      value={0}
      unit="alertas"
      link={{ href: '/veiculos', text: 'ver detalhes' }}
    />
  );
};
