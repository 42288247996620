import { useEffect, useMemo } from "react";

import FilterPeriodDatePicker from "../../components/FilterPeriodDatePicker";
import NavBar from "../../components/NavBar";
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/tabs";

import { useFilterContext } from "../../contexts/FilterContext";
import { useLoaderContext } from "../../contexts/LoaderContext";
import {
  useFilterVehiclesByLastMonth,
  useFilterVehiclesByLastWeek,
  useFilterVehiclesByRange,
  useFilterVehiclesByToday,
  useFilterVehiclesByYesterday,
} from "../../services/hooks/useFilterVehicles";
import {
  useFilterAlertsByLastMonth,
  useFilterAlertsByLastWeek,
  useFilterAlertsByRange,
  useFilterAlertsByToday,
  useFilterAlertsByYesterday,
} from "../../services/hooks/useFilterAlerts";
import { useFilterInactiveVehicles } from "../../services/hooks/useFilterVehicles";
import { useListBases } from "../../services/hooks/useListBases";
import { formatDateDDMMYY } from "../../lib/utils";
import { FilterVehicleType } from '../../models/FilterVehicleType';
import { FilterAlertType } from '../../models/FilterAlertType';




import VehicleBaseChart from "@/components/VehicleBaseChart";
import RecordingHoursChart from "@/components/RecordingHoursChart";
import ServiceTravelHoursChart from "@/components/ServiceTravelHoursChart";
import InactiveVehiclesTable from "@/components/InactiveVehiclesTable";
import AlertsChart from "@/components/AlertsChart";

export default function Dashboard() {
  const {
    base,
    filterDateRange,
    filterPeriod,
    setDisplayDateRange,
    setFilterPeriod,
  } = useFilterContext();
  const { handleStartLoader, handleStopLoader } = useLoaderContext();

  const today = new Date();

  const { data: bases = [], isLoading: loadingBases } = useListBases();

  const {
    data: vehiclesByToday = [],
    isLoading: loadingVehiclesToday,
  } = useFilterVehiclesByToday(base);

  const {
    data: vehiclesByYesterday = [],
    isLoading: loadingVehiclesYesterday,
  } = useFilterVehiclesByYesterday(base);

  const {
    data: vehiclesByLastWeek = [],
    isLoading: loadingVehiclesLastWeek,
  } = useFilterVehiclesByLastWeek(base);

  const {
    data: vehiclesByLastMonth = [],
    isLoading: loadingVehiclesLastMonth,
  } = useFilterVehiclesByLastMonth(base);

  const {
    data: vehiclesByRange = [],
    isLoading: loadingVehiclesRange,
  } = useFilterVehiclesByRange(
    !!filterDateRange?.from
      ? [filterDateRange.from, filterDateRange.to || filterDateRange.from || today]
      : [today, today],
    base
  );
  
  const {
    data: vehiclesNoCommunication = [],
    isLoading: loadingNoCommunication,
  } = useFilterInactiveVehicles(base);

  const {
    data: alertsByToday = [],
    isLoading: loadingAlertsToday,
  } = useFilterAlertsByToday(base);

  const {
    data: alertsByYesterday = [],
    isLoading: loadingAlertsYesterday,
  } = useFilterAlertsByYesterday(base);

  const {
    data: alertsByLastWeek = [],
    isLoading: loadingAlertsLastWeek,
  } = useFilterAlertsByLastWeek(base);

  const {
    data: alertsByLastMonth = [],
    isLoading: loadingAlertsLastMonth,
  } = useFilterAlertsByLastMonth(base);

  const {
    data: alertsByRange = [],
    isLoading: loadingAlertsRange,
  } = useFilterAlertsByRange(
    !!filterDateRange?.from
      ? [filterDateRange.from, filterDateRange.to || filterDateRange.from || today]
      : [today, today],
    base
  );

  useEffect(() => {
    if (!handleStartLoader || !handleStopLoader) {
      return;
    }

    if (
      loadingAlertsLastMonth ||
      loadingAlertsLastWeek ||
      loadingAlertsRange ||
      loadingAlertsToday ||
      loadingAlertsYesterday ||
      loadingBases ||
      loadingNoCommunication ||
      loadingVehiclesLastMonth ||
      loadingVehiclesLastWeek ||
      loadingVehiclesRange ||
      loadingVehiclesToday ||
      loadingVehiclesYesterday
    ) {
      handleStartLoader();
    } else {
      handleStopLoader();
    }
  }, [
    loadingAlertsLastMonth,
    loadingAlertsLastWeek,
    loadingAlertsRange,
    loadingAlertsToday,
    loadingAlertsYesterday,
    loadingBases,
    loadingNoCommunication,
    loadingVehiclesLastMonth,
    loadingVehiclesLastWeek,
    loadingVehiclesRange,
    loadingVehiclesToday,
    loadingVehiclesYesterday,
    handleStartLoader,
    handleStopLoader,
  ]);

  const selectedBaseName: string | null = useMemo(() => {
    const selectedBase = bases.find(
      (b) => b.chave.toLocaleLowerCase() === base?.toLocaleLowerCase(),
    );

    if (selectedBase?.nome?.length) {
      return selectedBase.nome;
    }

    return null;
  }, [bases, base]);

  const descriptionByFilterPeriod = useMemo(() => {
    switch (filterPeriod) {
      case 'today':
        return 'Hoje';
      case 'yesterday':
        return 'Ontem';
      case 'lastWeek':
        return 'Últimos 7 dias';
      case 'lastMonth':
        return 'Últimos 30 dias';
      case 'range':
        return `${!!filterDateRange?.from
          ? formatDateDDMMYY(filterDateRange.from.toISOString().split('T')[0])
          : ''
        }${!!filterDateRange?.to
          ? ` até ${formatDateDDMMYY(filterDateRange.to.toISOString().split('T')[0])}`
          : ''
        }`
      default:
        return '';
    }
  }, [filterDateRange, filterPeriod]);

  const vehiclesByFilterPeriod: FilterVehicleType[] = useMemo(() => {
    switch (filterPeriod) {
      case 'today':
        return vehiclesByToday;
      case 'yesterday':
        return vehiclesByYesterday;
      case 'lastWeek':
        return vehiclesByLastWeek;
      case 'lastMonth':
        return vehiclesByLastMonth;
        case 'range':
        return vehiclesByRange;
      default:
        return [];
    }
  }, [
    filterPeriod,
    vehiclesByLastMonth,
    vehiclesByLastWeek,
    vehiclesByRange,
    vehiclesByToday,
    vehiclesByYesterday,
  ]);

  const alertsByFilterPeriod: FilterAlertType[] = useMemo(() => {
    switch (filterPeriod) {
      case 'today':
        return alertsByToday;
      case 'yesterday':
        return alertsByYesterday;
      case 'lastWeek':
        return alertsByLastWeek;
      case 'lastMonth':
        return alertsByLastMonth;
        case 'range':
        return alertsByRange;
      default:
        return [];
    }
  }, [
    filterPeriod,
    alertsByLastMonth,
    alertsByLastWeek,
    alertsByRange,
    alertsByToday,
    alertsByYesterday,
  ]);

  const onClickTabTrigger = (period: string) => {
    const today = new Date();

    switch (period) {
      case 'today':
        setDisplayDateRange({ from: today })
        setFilterPeriod('today')
        return;

      case 'yesterday':
        const yesterday = new Date(today);

        yesterday.setDate(today.getDate() - 1);
        setDisplayDateRange({ from: yesterday })
        setFilterPeriod('yesterday')
        return;

      case 'lastWeek':
        const lastWeek = new Date(today);

        lastWeek.setDate(today.getDate() - 7);
        setDisplayDateRange({ from: lastWeek, to: today })
        setFilterPeriod('lastWeek')
        return;

      case 'lastMonth':
        const lastMonth = new Date(today);

        lastMonth.setDate(today.getDate() - 30);
        setDisplayDateRange({ from: lastMonth, to: today })
        setFilterPeriod('lastMonth')
        return;
    }
  };

  return (
    <>
      <NavBar bases={bases} />
      <main>
        <div className="pt-4 px-4 md:px-8 md:pt-8 mb-2">
          <Tabs value={filterPeriod}>
            <div className="w-fit flex items-center gap-3">
              <TabsList>
                <TabsTrigger
                  value="today"
                  onClick={() => onClickTabTrigger('today')}
                >
                  Hoje
                </TabsTrigger>
                <TabsTrigger
                  value="yesterday"
                  onClick={() => onClickTabTrigger('yesterday')}
                >
                  Ontem
                </TabsTrigger>
                <TabsTrigger
                  value="lastWeek"
                  onClick={() => onClickTabTrigger('lastWeek')}
                >
                  Última semana
                </TabsTrigger>
                <TabsTrigger
                  className="hidden sm:flex"
                  value="lastMonth"
                  onClick={() => onClickTabTrigger('lastMonth')}
                >
                  Último mês
                </TabsTrigger>
              </TabsList>
              <FilterPeriodDatePicker />
            </div>
          </Tabs>
        </div>
        <div className="flex flex-1 flex-col gap-4 pb-4 px-4 md:gap-8 md:pb-8 md:px-8">
          <div className="grid gap-4 lg:gap-8 lg:grid-cols-3">
            <VehicleBaseChart
              description={selectedBaseName || 'Todas as bases'}
              vehicles={vehiclesByLastMonth}
              baseList={bases}
            />
            <RecordingHoursChart
              description={descriptionByFilterPeriod}
              vehicles={vehiclesByFilterPeriod}
            />
            <ServiceTravelHoursChart
              description={descriptionByFilterPeriod}
              vehicles={vehiclesByFilterPeriod}
            />
          </div>
          <div className="grid gap-4 md:gap-8 lg:grid-cols-2 xl:grid-cols-3">
            <div className="xl:col-span-2">
              <InactiveVehiclesTable tableData={vehiclesNoCommunication} />
            </div>
            <div>
              <AlertsChart
                description={descriptionByFilterPeriod}
                alerts={alertsByFilterPeriod}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
