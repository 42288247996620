import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import FilterPeriodDatePicker from "@/components/FilterPeriodDatePicker";
import NavBar from "@/components/NavBar";
import VehicleDetailsCard from "@/components/VehicleDetailsCard";
import VideosTable from "@/components/VideosTable";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { useFilterContext } from "@/contexts/FilterContext";
import { useLoaderContext } from "@/contexts/LoaderContext";
import { useListVideos } from "@/services/hooks/useVideos";
import { TVehiclesCamerasByDate } from "@/models/TVehiclesCamerasByDate";

export default function Vehicle() {
  const { veiculoId = '' } = useParams();

  const {
    filterDateRange,
    filterPeriod,
    setDisplayDateRange,
    setFilterDateRange,
    setFilterPeriod,
  } = useFilterContext();
  const { handleStartLoader, handleStopLoader } = useLoaderContext();

  const [videos, setVideos] = useState<TVehiclesCamerasByDate[]>([]);
  const [storedVeiculoNome, setStoredVeiculoNome] = useState<string | null>(null);
  const [storedLocalidade, setStoredLocalidade] = useState<string | null>(null);

  const {
    data: vehicleVideos = [],
    isLoading: isLoadingListVideos
  } = useListVideos(
    veiculoId,
    (filterDateRange?.from || new Date()).toISOString().split('T')[0],
    filterDateRange?.to ? filterDateRange.to.toISOString().split('T')[0] : undefined,
  );

  useEffect(() => {
    if (!handleStartLoader || !handleStopLoader) {
      return;
    }
  
    if (isLoadingListVideos) {
      handleStartLoader();
    } else {
      const timeout = setTimeout(() => handleStopLoader(), 500);
      
      return () => clearTimeout(timeout);
    }
  }, [handleStartLoader, handleStopLoader, isLoadingListVideos]);

  useEffect(() => {
    if (
      !!videos?.[0]?.vehicles?.[0] &&
      !storedVeiculoNome &&
      !storedLocalidade
    ) {
      setStoredVeiculoNome(videos[0].vehicles[0].veiculoNome);
      setStoredLocalidade(videos[0].vehicles[0].localidade || '');
    }
  }, [vehicleVideos, storedVeiculoNome, storedLocalidade, videos]);

  useEffect(() => {
    if (!vehicleVideos?.length) {
      return;
    }

    const getVideos = async () => {
      const startObject = filterDateRange?.from || new Date();
      const finalObject = filterDateRange?.to || startObject;
      const formattedStart = startObject.toISOString().split('T')[0];
      const formattedFinal = finalObject.toISOString().split('T')[0];

      if (
        !startObject ||
        !finalObject ||
        !formattedStart?.length  ||
        !formattedFinal?.length
      ) {
        return;
      }

      for (
        let date = new Date(startObject);
        date <= finalObject;
        date.setDate(date.getDate() + 1)
      ) {
        const formattedDate = date.toISOString().split('T')[0];
        const vehicleCamera = vehicleVideos?.filter(
          (f) => f.dataDoVideo === formattedDate,
        );

        if (!vehicleCamera || vehicleCamera.length === 0) {
          continue;
        }

        setVideos((previous) => {
          const dateIndex = previous.findIndex(
            (element) => element.date === formattedDate,
          );

          if (dateIndex === -1) {
            return ([
              ...previous,
              {
                date: formattedDate,
                vehicles: vehicleCamera || [],
              },
            ]);
          }

          return previous.map((element) => element.date === formattedDate
            ? {
              date: formattedDate,
              vehicles: vehicleCamera || [],
            }
            : element
          );
        });
      }
    };

    getVideos();
  }, [filterDateRange, vehicleVideos]);

  useEffect(() => {
    setVideos([]);

    const today = new Date();
    
    switch (filterPeriod) {
      case 'today':
        setFilterDateRange({ from: today })
        return;

      case 'yesterday':
        const yesterday = new Date(today);

        yesterday.setDate(today.getDate() - 1);
        setFilterDateRange({ from: yesterday })
        return;

      case 'lastWeek':
        const lastWeek = new Date(today);

        lastWeek.setDate(today.getDate() - 7);
        setFilterDateRange({ from: lastWeek, to: today });
        return;

      case 'lastMonth':
        const lastMonth = new Date(today);

        lastMonth.setDate(today.getDate() - 30);
        setFilterDateRange({ from: lastMonth, to: today });
        return;
    }
  }, [filterPeriod, setFilterDateRange]);

  const onClickTabTrigger = (period: string) => {
    const today = new Date();

    switch (period) {
      case 'today':
        setDisplayDateRange({ from: today })
        setFilterPeriod('today')
        return;

      case 'yesterday':
        const yesterday = new Date(today);

        yesterday.setDate(today.getDate() - 1);
        setDisplayDateRange({ from: yesterday })
        setFilterPeriod('yesterday')
        return;

      case 'lastWeek':
        const lastWeek = new Date(today);

        lastWeek.setDate(today.getDate() - 7);
        setDisplayDateRange({ from: lastWeek, to: today })
        setFilterPeriod('lastWeek')
        return;

      case 'lastMonth':
        const lastMonth = new Date(today);

        lastMonth.setDate(today.getDate() - 30);
        setDisplayDateRange({ from: lastMonth, to: today })
        setFilterPeriod('lastMonth')
        return;
    }
  };

  return (
    <>
      <NavBar bases={[]} />
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <div>
          <div className="mb-2">
            <Tabs value={filterPeriod}>
              <div className="w-fit flex items-center gap-3">
                <TabsList>
                  <TabsTrigger value="today" onClick={() => onClickTabTrigger('today')}>
                    Hoje
                  </TabsTrigger>
                  <TabsTrigger
                    value="yesterday"
                    onClick={() => onClickTabTrigger('yesterday')}
                  >
                    Ontem
                  </TabsTrigger>
                  <TabsTrigger value="lastWeek" onClick={() => onClickTabTrigger('lastWeek')}>
                    Última semana
                  </TabsTrigger>
                  <TabsTrigger
                    className="hidden sm:flex"
                    value="lastMonth"
                    onClick={() => onClickTabTrigger('lastMonth')}
                  >
                    Último mês
                  </TabsTrigger>
                </TabsList>
                <FilterPeriodDatePicker />
              </div>
            </Tabs>
          </div>
          <Card x-chunk="dashboard-06-chunk-0">
            <CardHeader>
              <CardTitle>Veículo: {storedVeiculoNome}</CardTitle>
              <CardDescription>Base: {storedLocalidade}</CardDescription>
            </CardHeader>
            <CardContent>
              <VehicleDetailsCard videos={vehicleVideos} />
              <VideosTable
                key={`${videos?.[0]?.date || ''}-${videos?.[videos?.length - 1]?.date || ''}`}
                vehicleVideos={videos}
              />
            </CardContent>
          </Card>
        </div>
      </main>
    </>
  );
};
