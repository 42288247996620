import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
// import { ReactQueryDevtools } from 'react-query/devtools'

import { Router } from "./routes/Router";
import { FilterProvider } from "./contexts/FilterContext";
import { LoaderProvider } from "./contexts/LoaderContext";
import { msalConfig } from "./services/authConfig";
import { Toaster } from "./components/ui/toaster";

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

export default function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <LoaderProvider>
          <FilterProvider>
            <BrowserRouter>
              <div className="flex min-h-screen w-full flex-col max-w-[1440px] mx-auto">
                <Router />
                <Toaster />
              </div>
            </BrowserRouter>
          </FilterProvider>
        </LoaderProvider>
      </QueryClientProvider>
    </MsalProvider>
  );
};
