export const ROUTES_PATH = {
  dashboard: '/',
  fleet: '/veiculos',
  veiculo: '/veiculos/:veiculoId',
  reports: 'relatorios',

  // basePainel: '/:base',
  // baseVeiculos: '/:base/veiculos/:startDate?/:finalDate?',
  // painel: '/',
  
  // veiculos: '/veiculos/:startDate?/:finalDate?',
  videos: '/veiculos/:veiculoId/:startDate/videos/:startHour?/:videoTime?',
};
