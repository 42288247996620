import { create, StateCreator } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';

type AuthState = {
  token: string;
  setToken: (token: string) => void;
  signOut: () => void;
};

type PersistProps = (
  config: StateCreator<AuthState>,
  options: PersistOptions<AuthState>,
) => StateCreator<AuthState>;

export const authStore = create<AuthState>(
  (persist as PersistProps) (
    (set, get) => {
      return {
        token: '',
        setToken: (token: string) => {
          set(() => ({ token }));
        },
        signOut: () => {
          set(() => ({
            token: '',
            email: '',
          }));
        },
      };
    },
    {
      name: 'auth-storage',
    },
  ),
);

export const useAuth = authStore;
