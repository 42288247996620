import { VideoOffIcon } from 'lucide-react';
import { cn, formatVideoDuration } from '../../../../lib/utils';

import 'react-datepicker/dist/react-datepicker.css';
import { ScrollArea, ScrollBar } from '../../../ui/scroll-area';
// import { ScrollBar } from '@/components/ui/scroll-area';

interface VideoTimelapseControlProps {
  played: number;
  duration: number;
  videoFullscreen: boolean;
  activeVideo: number;
  timeLapse?: string[];
  handleSliderSeek: (value: number) => void;
};

const slices = 15;

export default function VideoTimelapseControl({
  played,
  duration,
  videoFullscreen,
  activeVideo,
  timeLapse,
  handleSliderSeek,
}: VideoTimelapseControlProps) {
  const handleClickThumbnail = (piece: number, max: number) => {
    const value = piece / (max || 1);

    handleSliderSeek(value);
  };

  return (
    <ScrollArea className="lg:w-full">
      <div
        className={cn(
          'h-fit mt-4 py-2 px-0 flex',
          'lg:grid lg:grid-cols-15 lg:min-w-100vw lg:px-1',
          videoFullscreen && 'fixed w-full bottom-6 left-1/2 -translate-x-1/2 justify-center bg-black/50 z-40'
        )}
      >
        {Array.from({ length: slices }, (_, i) => i).map((slice) => {
          const activated = played >= (1 / slices) * slice && played < (1 / slices) * (slice + 1);
          const image = timeLapse?.[slice];

          return (
            <button
              key={slice}
              className={cn(
                'flex-none w-24 aspect-video lg:w-full relative block ring-2 ring-offset-2 ring-offset-transparent ring-transparent transition-all text-2xs',
                'bg-neutral-600',
                activated && 'ring-white ring-offset-black z-10',
              )}
              onClick={() => handleClickThumbnail(slice, 15)}
            >
              {!!image ? (
                <img className="block w-full object-cover" src={image} alt={`Imagem ${slice} do vídeo`} />
              ) : (
                <div className="aspect-video bg-neutral-600">
                  <span className="absolute top-0.5 left-1.5">{`CAM ${activeVideo + 1}`}</span>
                  <VideoOffIcon
                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 stroke-neutral-500"
                    size={16}
                  />
                </div>
              )}
              <span
                className={cn(
                  'absolute bottom-0.5 right-1.5',
                  !!image && 'text-shadow-lg shadow-black',
              )}>
                {formatVideoDuration(duration * (slice / slices))}
              </span>
            </button>
          );
        })}
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
};
